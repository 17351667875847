/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UserAvatarLogOutMutationVariables = {};
export type UserAvatarLogOutMutationResponse = {
    readonly logOut: {
        readonly success: boolean;
    };
};
export type UserAvatarLogOutMutation = {
    readonly response: UserAvatarLogOutMutationResponse;
    readonly variables: UserAvatarLogOutMutationVariables;
};



/*
mutation UserAvatarLogOutMutation {
  logOut {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutationResponse",
    "kind": "LinkedField",
    "name": "logOut",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAvatarLogOutMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserAvatarLogOutMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1d2d477c73d70affead9039ca77fe293",
    "id": null,
    "metadata": {},
    "name": "UserAvatarLogOutMutation",
    "operationKind": "mutation",
    "text": "mutation UserAvatarLogOutMutation {\n  logOut {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a6b88e3e60b723e1d0fc626ce54edd33';
export default node;
