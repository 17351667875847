import * as React from 'react';
import {
  ThemeIcon,
  NavLink,
  Title,
  NavLinkProps,
  UnstyledButton,
} from '@mantine/core';
import {useRouter} from 'next/router';
import type {PolymorphicComponentProps} from '@mantine/utils';

const matchesRoutePrefix = (
  routePath: string,
  pathPrefix?: string,
): boolean => {
  if (pathPrefix == null) {
    return false;
  }

  if (pathPrefix === '/') {
    return routePath === '/';
  }

  return routePath.startsWith(pathPrefix);
};

export const NavLinkItem: React.FC<
  {
    color: React.ComponentProps<typeof ThemeIcon>['color'];
    label: string;
    icon: React.ReactNode;
    showLabel?: boolean;
  } & PolymorphicComponentProps<'a', NavLinkProps>
> = ({label, icon, color, showLabel = true, ...rest}) => {
  const router = useRouter();
  const active = rest.active ?? matchesRoutePrefix(router.pathname, rest.href);

  if (!showLabel) {
    return (
      <UnstyledButton component="a" href={rest.href}>
        <ThemeIcon
          color={color}
          variant="light"
          size="xl"
          sx={active ? {transform: 'scale(1.2)'} : undefined}
        >
          {icon}
        </ThemeIcon>
      </UnstyledButton>
    );
  }

  return (
    <NavLink
      {...rest}
      icon={
        <ThemeIcon color={color} variant="light" size="xl">
          {icon}
        </ThemeIcon>
      }
      label={showLabel ? <Title order={4}>{label}</Title> : undefined}
      component="a"
      active={active}
    />
  );
};
