import * as React from 'react';
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  Container,
  Box,
  Group,
  ThemeIcon,
  Title,
  Center,
} from '@mantine/core';
import {NavLinkItem} from '../ui/NavLinkItem';
import {IconList, IconPhoto, IconSettings, IconSquarePlus} from '@tabler/icons';
import {ErrorBoundary} from 'react-error-boundary';
import {graphql, useFragment} from 'react-relay';
import {AppLayout_query$key} from '../../queries/__generated__/AppLayout_query.graphql';
import {UserAvatar} from '../user/UserAvatar';
import {CenteredLoading} from '../ui/CenteredLoading';

const NavLinks: React.FC<{showLabel?: boolean}> = ({showLabel = true}) => {
  return (
    <>
      <NavLinkItem
        label="Create"
        icon={<IconSquarePlus />}
        color="indigo"
        href="/create"
        showLabel={showLabel}
      />
      <NavLinkItem
        label="Entries"
        icon={<IconList />}
        color="lime"
        href="/entries"
        showLabel={showLabel}
      />
      <NavLinkItem
        label="Settings"
        icon={<IconSettings />}
        color="gray"
        href="/settings"
        showLabel={showLabel}
      />
    </>
  );
};

export const AppLayout: React.FC<{
  children?: React.ReactNode;
  query$key: AppLayout_query$key;
}> = ({children, query$key}) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = React.useState(false);
  const query = useFragment(
    graphql`
      fragment AppLayout_query on Query {
        me {
          user {
            ...UserAvatar_user
          }
        }
      }
    `,
    query$key,
  );

  return (
    <AppShell
      // styles={{
      //   main: {
      //     background:
      //       theme.colorScheme === 'dark'
      //         ? theme.colors.dark[8]
      //         : theme.colors.gray[0],
      //   },
      // }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{sm: 200, lg: 300}}
        >
          <Navbar.Section>
            <NavLinks />
          </Navbar.Section>
        </Navbar>
      }
      aside={
        <MediaQuery smallerThan="sm" styles={{display: 'none'}}>
          <Box
            sx={theme => ({
              width: 200,
              [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
                width: 300,
              },
            })}
          />
        </MediaQuery>
      }
      footer={
        <MediaQuery largerThan="md" styles={{display: 'none'}}>
          <Footer height={60} p="xs">
            <Center>
              <Group sx={{width: '100%'}} grow>
                <Center>
                  <NavLinkItem
                    label="Entries"
                    icon={<IconList />}
                    color="lime"
                    href="/entries"
                    showLabel={false}
                  />
                </Center>
                <Center>
                  <NavLinkItem
                    label="Create"
                    icon={<IconSquarePlus />}
                    color="indigo"
                    href="/create"
                    showLabel={false}
                  />
                </Center>
                <Center>
                  <NavLinkItem
                    label="Settings"
                    icon={<IconSettings />}
                    color="gray"
                    showLabel={false}
                    href="/settings"
                  />
                </Center>
              </Group>
            </Center>
          </Footer>
        </MediaQuery>
      }
      header={
        <Header height={70} p="xl">
          <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <MediaQuery largerThan="sm" styles={{display: 'none'}}>
              <Burger
                opened={opened}
                onClick={() => setOpened(o => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <Group position="apart" sx={{width: '100%'}}>
              <Group>
                <ThemeIcon
                  size="xl"
                  variant="gradient"
                  gradient={{from: '#ed6ea0', to: '#ec8c69', deg: 35}}
                >
                  <IconPhoto size={20} />
                </ThemeIcon>
                <Title order={3}>Happily</Title>
              </Group>
              {query?.me?.user != null && (
                <UserAvatar user$key={query.me.user} />
              )}
            </Group>
          </div>
        </Header>
      }
    >
      <Container h="100%">
        <ErrorBoundary
          fallback={<span>Error</span>}
          onError={err => console.log('page error: ', err)}
        >
          <React.Suspense fallback={<CenteredLoading />}>
            {children}
          </React.Suspense>
        </ErrorBoundary>
      </Container>
    </AppShell>
  );
};
