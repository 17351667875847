/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserAvatar_user = {
    readonly name: string;
    readonly " $refType": "UserAvatar_user";
};
export type UserAvatar_user$data = UserAvatar_user;
export type UserAvatar_user$key = {
    readonly " $data"?: UserAvatar_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserAvatar_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserAvatar_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'bad0922dee4ed93f12e769874378c7f9';
export default node;
