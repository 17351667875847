import type {
  GetServerSideProps,
  GetServerSidePropsResult,
  NextPage,
} from 'next';
import * as React from 'react';
import {Cookie, useCookie as getCookie} from 'next-cookie';
import {WiredOptions, WiredProps} from 'relay-nextjs/wired/component';
import {RelayProp} from 'react-relay';
import {RelayProps} from 'relay-nextjs';
import {OperationType} from 'relay-runtime';

export const authRedirect: WiredOptions<
  WiredProps<{}, OperationType>
>['serverSideProps'] = async ctx => {
  const req = ctx.req;
  if (req != null) {
    const cookie = getCookie(req.headers.cookie);
    const authToken = cookie.get<string | null>('auth_token');

    if (authToken == null) {
      return {
        redirect: {
          destination: `/auth/login?u=${encodeURIComponent(ctx.asPath ?? '/')}`,
          permanent: false,
        },
      };
    }
  }

  return {props: {}};
};

export function withAuthProtection<TProps>(
  Component: React.ComponentType<TProps> & {
    getInitialProps?: NextPage['getInitialProps'];
  },
) {
  return Component;
  // const originalGetInitialProps = Component.getInitialProps;

  // Component.getInitialProps = async ctx => {
  //   if (ctx.req != null && ctx.res != null) {
  //     const cookie = getCookie(ctx.req.headers.cookie);
  //     const authToken = cookie.get<string | null>('auth_token');

  //     if (authToken == null) {
  //       ctx.res.writeHead(307, {
  //         Location: `/auth/login?u=${encodeURIComponent(ctx.asPath ?? '/')}`,
  //       });
  //       ctx.res.end();
  //       return null;
  //     }
  //   }

  //   if (originalGetInitialProps != null) {
  //     return await originalGetInitialProps(ctx);
  //   }

  //   //   return {};
  // };

  return Component;
}
