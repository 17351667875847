import {Center, Loader} from '@mantine/core';
import * as React from 'react';

export const CenteredLoading: React.FC = () => {
  return (
    <Center>
      <Loader />
    </Center>
  );
};
