export class OnlyExecutableOnClientError extends Error {
  public constructor() {
    super('Client-only code-path reached.');
  }
}

export const assertClient = () => {
  if (typeof window === 'undefined') {
    throw new OnlyExecutableOnClientError();
  }
};
