import {Decrypt, Encrypt} from '../entries/Encryption';
import {assertClient} from '../shared/assertClient';

const LOCAL_KEY = 'application_local_key';
const STORAGE_KEY = '@happily/pk';
let cachedPrivateKey: string | null = null;

export const clear = () => {
  assertClient();
  cachedPrivateKey = null;
  window.localStorage.removeItem(STORAGE_KEY);
};

export const setPrivateKey = async (privateKey: string) => {
  assertClient();
  cachedPrivateKey = privateKey;
  // TODO: Change how get local key
  const localEncrypted = await Encrypt.string.scalar(privateKey, LOCAL_KEY);
  window.localStorage.setItem(STORAGE_KEY, localEncrypted);
};

export const getPrivateKey = async (): Promise<string> => {
  assertClient();
  if (cachedPrivateKey != null) {
    return cachedPrivateKey;
  }

  const localEncrypted = window.localStorage.getItem(STORAGE_KEY);
  if (localEncrypted == null) {
    throw new Error('Attempted to get private key when not logged in.');
  }

  const decrypted = await Decrypt.string.scalar(localEncrypted, LOCAL_KEY);
  cachedPrivateKey = decrypted;
  return decrypted;
};
