import {Avatar, Menu} from '@mantine/core';
import {IconSettings} from '@tabler/icons';
import {useRouter} from 'next/router';
import * as React from 'react';
import {graphql, useFragment, useMutation} from 'react-relay';
import {clear} from '../../lib/KeyStore';

import {loginMutation} from '../../queries/__generated__/loginMutation.graphql';
import {UserAvatarLogOutMutation} from '../../queries/__generated__/UserAvatarLogOutMutation.graphql';
import {UserAvatar_user$key} from '../../queries/__generated__/UserAvatar_user.graphql';
import {AuthContext} from '../auth/AuthContext';

export const UserAvatar: React.FC<{
  user$key: UserAvatar_user$key;
}> = ({user$key}) => {
  const user = useFragment(
    graphql`
      fragment UserAvatar_user on User {
        name
      }
    `,
    user$key,
  );

  const [commitLogOutMutation, isLoading] =
    useMutation<UserAvatarLogOutMutation>(graphql`
      mutation UserAvatarLogOutMutation {
        logOut {
          success
        }
      }
    `);

  const router = useRouter();
  const setIsAuthenticated =
    React.useContext(AuthContext).setIsAuthenticated__DO_NOT_USE;

  const logOut = () => {
    commitLogOutMutation({
      variables: {},
      onCompleted: (res, errors) => {
        if (!res.logOut.success) {
          // TODO: Error
          console.log('Failed to logout: ', errors);
          return;
        }
        clear();
        setIsAuthenticated(false);
        router.push('/');
      },
    });
  };

  return (
    <Menu width={200}>
      <Menu.Target>
        <Avatar radius="xl" sx={{cursor: 'pointer'}} />
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{user.name}</Menu.Label>
        <Menu.Item icon={<IconSettings size={14} />}>Settings</Menu.Item>
        <Menu.Divider />
        <Menu.Item color="red" onClick={logOut}>
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
