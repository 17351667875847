import React, {ComponentType} from 'react';
import {withRelay, RelayProps} from 'relay-nextjs';
import type {GraphQLTaggedNode, OperationType} from 'relay-runtime';
import {getClientEnvironment} from './getClientEnvironment';
import type {NextPageContext, Redirect} from 'next';
import type {NextRouter} from 'next/router';
import {WiredOptions} from 'relay-nextjs/wired/component';
import {authRedirect} from './withAuthProtection';

// interface Foo extends WiredOptions {

// }

// export type Options<Props extends RelayProps<{}>, ServerSideProps = {}> = {
//   fallback?: React.ReactNode;
//   variablesFromContext?: (
//     ctx: NextPageContext | NextRouter,
//   ) => Props['preloadedQuery']['variables'];
//   clientSideProps?: (ctx: NextPageContext) => void | {
//     redirect: Redirect;
//   };
//   serverSideProps?: (ctx: NextPageContext) => Promise<
//     | ServerSideProps
//     | {
//         redirect: Redirect;
//       }
//   >;
// };

export default function createPreloadedPage<
  Props extends {} = {},
  Query extends OperationType = OperationType,
  ServerSideProps = {},
>(
  Component: ComponentType<RelayProps<Props, Query>>,
  Query: GraphQLTaggedNode,
  opts: Partial<WiredOptions<RelayProps<Props, Query>, ServerSideProps>> = {},
  withAuthProtection: boolean = true,
) {
  if (opts.serverSideProps == null && withAuthProtection) {
    opts.serverSideProps = authRedirect as any;
  }
  return withRelay(Component, Query, {
    ...opts,
    // Fallback to render while the page is loading.
    // This property is optional.
    // fallback: <Loading />,
    // Create a Relay environment on the client-side.
    // Note: This function must always return the same value.
    createClientEnvironment: () => getClientEnvironment()!,
    // Gets server side props for the page.
    // serverSideProps: undefined,
    // serverSideProps,
    // serverSideProps: undefined, // ?? (withAuthProtection ? authRedirect : undefined),
    // serverSideProps: async ctx => {
    //   // This is an example of getting an auth token from the request context.
    //   // If you don't need to authenticate users this can be removed and return an
    //   // empty object instead.
    //   const {getTokenFromCtx} = await import('lib/server/auth');
    //   const token = await getTokenFromCtx(ctx);
    //   if (token == null) {
    //     return {
    //       redirect: {destination: '/login', permanent: false},
    //     };
    //   }

    //   return {token};
    // },
    // Server-side props can be accessed as the second argument
    // to this function.
    createServerEnvironment: async (
      ctx,
      // The object returned from serverSideProps. If you don't need a token
      // you can remove this argument.
      // {token}: {token: string},
    ) => {
      // console.log('req.cookie: ', ctx.req?.headers);
      // console.log('server ctx: ', ctx);
      // console.log('server?', __dirname);
      const {createServerEnvironment} = await import(
        '../server/createServerRelayEnvironment'
      );
      return createServerEnvironment(ctx.req?.headers.cookie ?? null);
    },
  });
}
