import CryptoJS from 'crypto-js';
import memoize from 'lodash/memoize';

// Implement
const encrypt = (value: string, key: string): string => {
  return CryptoJS.AES.encrypt(value, key).toString();
};

const decrypt = (value: string, key: string): string => {
  return CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
};
// End Implementation

type EncryptableScalar = string | number | boolean | Date;

type EncryptionFunction<
  TInput,
  TOutput extends string | string[] | {[key: string]: string},
> = (value: TInput, key: string) => Promise<TOutput>;

type ScalarEncryptionFunction<TInput extends EncryptableScalar> =
  EncryptionFunction<TInput, string>;

type EncryptionFunctions<TInput extends EncryptableScalar> = {
  scalar: ScalarEncryptionFunction<TInput>;
  object: EncryptionFunction<{[key: string]: TInput}, {[key: string]: string}>;
  array: EncryptionFunction<TInput[], string[]>;
};

type DecryptionFunction<
  TInput extends string | {[key: string]: string} | string[],
  TOutput,
> = (value: TInput, key: string) => Promise<TOutput>;

type ScalarDecryptionFunction<TOutput> = (
  value: string,
  key: string,
) => Promise<TOutput>;

type DecryptionFunctions<TOutput extends EncryptableScalar> = {
  scalar: ScalarDecryptionFunction<TOutput>;
  object: DecryptionFunction<{[key: string]: string}, {[key: string]: TOutput}>;
  array: DecryptionFunction<string[], TOutput[]>;
};

const defer = <T>(fn: () => T): Promise<T> =>
  new Promise(res => setTimeout(() => res(fn()), 0));

// Scalar Encryptors
const encryptString: ScalarEncryptionFunction<string> = (v, k) =>
  defer(() => encrypt(v, k));
const encryptNumber: ScalarEncryptionFunction<number> = (v, k) =>
  defer(() => encrypt(v.toString(), k));
const encryptBoolean: ScalarEncryptionFunction<boolean> = (v, k) =>
  defer(() => encrypt(v.toString(), k));
const encryptDate: ScalarEncryptionFunction<Date> = (v, k) =>
  defer(() => encrypt(v.toISOString(), k));

// Scalar Decryptors
const decryptString: ScalarDecryptionFunction<string> = (v, k) =>
  defer(() => decrypt(v, k));
const decryptNumber: ScalarDecryptionFunction<number> = (v, k) =>
  defer(() => Number(decrypt(v, k)));
const decryptBoolean: ScalarDecryptionFunction<boolean> = (v, k) =>
  defer(() => Boolean(decrypt(v, k)));
const decryptDate: ScalarDecryptionFunction<Date> = (v, k) =>
  defer(() => new Date(decrypt(v, k)));

const encryptArray = <TInput extends EncryptableScalar>(
  encryptionFn: ScalarEncryptionFunction<TInput>,
  values: TInput[],
  key: string,
): Promise<string[]> => {
  return Promise.all(values.map(v => encryptionFn(v, key)));
};

const encryptObject = async <TInput extends EncryptableScalar>(
  encryptionFn: ScalarEncryptionFunction<TInput>,
  values: {[key: string]: TInput},
  key: string,
): Promise<{[key: string]: string}> => {
  const entries = await Promise.all(
    Object.entries(values).map(async ([k, v]) => {
      const encrypted = await encryptionFn(v, k);
      return [k, encrypted];
    }),
  );

  return Object.fromEntries(entries);
};

const decryptArray = <TOutput extends EncryptableScalar>(
  decryptionFn: ScalarDecryptionFunction<TOutput>,
  values: string[],
  key: string,
): Promise<TOutput[]> => {
  return Promise.all(
    values.map(async v => await defer(() => decryptionFn(v, key))),
  );
};

const decryptObject = async <TOutput extends EncryptableScalar>(
  decryptionFn: ScalarDecryptionFunction<TOutput>,
  values: {[key: string]: string},
  key: string,
): Promise<{[key: string]: TOutput}> => {
  const entries = await Promise.all(
    Object.entries(values).map(async ([k, v]) => {
      const encrypted = await defer(() => decryptionFn(v, k));
      return [k, encrypted];
    }),
  );

  return Object.fromEntries(entries);
};

const createEncryptionFunctions = <TInput extends EncryptableScalar>(
  scalar: ScalarEncryptionFunction<TInput>,
): EncryptionFunctions<TInput> => ({
  scalar,
  object: (value, key) => encryptObject(scalar, value, key),
  array: (value, key) => encryptArray(scalar, value, key),
});

const createDecryptionFunctions = <TOutput extends EncryptableScalar>(
  scalar: ScalarDecryptionFunction<TOutput>,
): DecryptionFunctions<TOutput> => ({
  scalar,
  object: (value, key) => decryptObject(scalar, value, key),
  array: (value, key) => decryptArray(scalar, value, key),
});

export const Encrypt = {
  string: createEncryptionFunctions(encryptString),
  number: createEncryptionFunctions(encryptNumber),
  boolean: createEncryptionFunctions(encryptBoolean),
  date: createEncryptionFunctions(encryptDate),
};

export const Decrypt = {
  string: createDecryptionFunctions(decryptString),
  number: createDecryptionFunctions(decryptNumber),
  boolean: createDecryptionFunctions(decryptBoolean),
  date: createDecryptionFunctions(decryptDate),
};

const sumKey = memoize((key: string): number => {
  let sum = 0;
  for (let i = 0; i < key.length; i++) {
    sum += key.charCodeAt(i);
  }
  return sum;
});

const encryptVector = (
  vec: number[],
  privateKey: string,
  places: number = 5,
): number[] => {
  if (places < 2 || places > 15) {
    throw new Error('Precision places must be in [2, 15]');
  }

  const multiplier = 1 / (sumKey(privateKey) % Math.pow(10, places));

  return vec.map(c => c * multiplier);
};

const decryptVector = (
  vec: number[],
  privateKey: string,
  places: number = 5,
): number[] => {
  if (places < 2 || places > 15) {
    throw new Error('Precision places must be in [2, 15]');
  }
  const multiplier = sumKey(privateKey) % Math.pow(10, places);

  return vec.map(c => c * multiplier);
};

export const ML = {
  encryptVector: encryptVector,
  decryptVector: decryptVector,
};
